/* You can add global styles to this file, and also import other style files */
// @import url('./assets/css/style.css');
@import 'node_modules/angular2-toaster/toaster';

:root {
    --mainBG: rgba(0,0,0, 0.3) !important;
    --colorTheme: #000 !important;
    --colorHeading: #000!important;
    --colorText: #000!important;
    --colorBorder: #000!important;
    --colorBox: rgba(0,0,0, 0.3) !important;
	--colorText: #000 !important;
    --colorWhite: #ffffff!important;
	--cart-btn-color: #000 !important;
	--cart-btn-color-2: #fff !important;
	--card-header: #E5BC7E26 !important;
	--theme-font: 'Barlow', sans-serif;
	--icon-font: "Font Awesome 5 Free";
	--color1: #5da205 !important;
	--color2: #f78888 !important;
	--color3: #ececec !important;
    --productListBg: #ecececb3 !important;
	--color4: #90ccf4 !important;
	--color5: #f3d250 !important;
	--colorBgShade: #ECF5F9 !important;
    --colorSliderSlot: #ffede5 !important;
}
body, html, *, h1, h2, h3, h4, h5, div, p, span, i, strong, label, select, input, button {
    font-family: 'Barlow', sans-serif;
}
* {
    padding: 0;
    margin: 0;
    outline: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
a {
	color: var(--colorText);
}
a, a:active, a:focus, a:hover, a:visited {
	text-decoration: none;
	outline: none;
	box-shadow: none !important;
}
a:hover {
	color: var(--colorText);
}
.font-20 {
	font-size: 20px !important;
}
.font-22 {
	font-size: 22px !important;
}
.font-24 {
	font-size: 24px !important;
}
.font-26 {
	font-size: 26px !important;
}
.font-28 {
	font-size: 28px !important;
}
.font-30 {
	font-size: 30px !important;
}
.font-34 {
	font-size: 34px !important;
}
.pointer{
	cursor: pointer;
}
.login-hyper{
	padding-left: 25px;
}
.toast:not(.show){
	display: flex !important;
}
.top5{
	top: 15%;
}
.themeColor{
	color: var(--colorText) !important;
}
.themeBG{
	background-color: var(--colorText) !important;
}
.invalid-feedback{
	display: block;
}
.btn-theme {
	// background-color: var(--colorTheme);
    // border: 2px solid var(--colorTheme);
    // border-radius: 50px;
    // font-size: 18px;
    // font-weight: 600;
    // color: var(--colorWhite);
    // padding: 10px 27px;
    // margin-top: 20px;
    // display: inline-block;
	background-color: #000;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    letter-spacing: .05em;
    text-transform: uppercase;
	padding: 10px 27px;
    border: 2px solid #000000;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    transition: all .5s;
}
.btn-theme i {
    margin-left: 10px;
    font-size: 16px;
}
.btn-theme:hover {
	font-weight: 600;
    background-color: transparent;
    -webkit-transition: .4s;
    transition: .4s;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--colorText);
	font-weight: 500;
	font-size: medium;
    background-color: var(--colorWhite);
    background-clip: padding-box;
    border: 1px solid var(--colorText);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	box-shadow: none;
}
.form-control:focus {
    color: var(--colorText);
    background-color: var(--colorWhite);
    border-color: var(--colorText);
    outline: 0;
    box-shadow: none;
}
.form-control::placeholder {
	font-weight: 100;
	color: rgba(0, 0, 0, 0.75);
	background-color: var(--colorWhite);
}
input::-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: var(--colorWhite) !important;
    color: var(--colorText) !important;
}
.form-select{
	width: 100%;
	position: relative;
	color: var(--colorText);
	background-color: var(--colorWhite);
	border: 1px solid var(--colorText);
	background-image: url('~src/assets/img/form-select-arrow-theme.svg');
	outline: none;
	box-shadow: none;
	cursor: pointer;
}
.form-control:disabled, .form-control, select:disabled, .form-select:disabled{
    background-color: var(--colorWhite);
}
.form-select:focus {
	border-color: var(--colorText);
	outline: none;
	box-shadow: none;
}
option {
	cursor: pointer !important;
    display: block;
    white-space: nowrap;
    min-height: 40px !important;
	line-height: 40px !important;
    padding: 10px 3px !important;
    font-weight: 500 !important;
    margin: 3px 0 !important;
	// background-color: var(--cart-btn-color) !important;
}
option:hover,option:focus,option:active {
	background-color: var(--cart-btn-color) !important;
}
input[type=radio] {
    visibility: hidden;
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    display: none;
}
label {
    font-size: 14px;
    font-weight: 700;
    color: var(--colorText);
    margin-bottom: 10px;
    line-height: 1;
}
.accordian-section .card .card-body .html-preview *{
	font-size: 16px;
	letter-spacing: 1px;
    line-height: 1.5;
}
// input[type=radio]~label {
//     position: relative;
//     padding-left: 25px;
//     cursor: pointer;
//     display: block;
//     line-height: 1.3;
//     margin-bottom: 11px;
// }
// input[type=radio]~label:after, input[type=radio]~label:before {
//     content: "";
//     position: absolute;
//     border-radius: 100%;
//     transition: all .2s ease;
// }
// input[type=radio]~label:after {
//     width: 10px;
//     height: 10px;
//     top: 4px;
//     left: 3px;
//     opacity: 0;
//     transform: scale(0);
// }
// input[type=radio]~label::before {
//     top: 1px;
//     left: 0;
//     width: 16px;
//     height: 16px;
//     border: 1px solid var(--colorBorder);
//     background: var(--mainBG);
// }
// input[type=radio]:checked~label::before {
//     border-color: var(--colorHeading);
// }
// input[type=radio]:checked~label:after {
//     opacity: 1;
//     background-color: var(--colorHeading);
//     transform: scale(1);
// }
